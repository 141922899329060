<template>

    <zw-sidebar @shown="shown" :title="$t('common.title.upload_file')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>

                    <b-col sm="12">
                        <zw-file-group v-model="form.file"
                                       name="file"
                                       :label-prefix="labelPrefix"
                                       document-type="-"
                                       accept="txt"
                        ></zw-file-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-xs-center">

                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>

                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'common.label.',
            payload: {},
            callback: null,
            form: {
                file: null,
            },
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.form.data = this.payload.data
                    this.$store.dispatch(this.payload.store_dispatch_name, this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>